const hotPointsData = [
  {
    id: 1,
    area_id: 6545,
    area_name: "广州白云机场",
    area_shape: "圆形",
    round_radius: 2,
    area_population: 12655,
    center_lon: "113.307605",
    center_lat: "23.389929",
  },
  {
    id: 2,
    area_id: 5510,
    area_name: "广州东站",
    area_shape: "圆形",
    round_radius: 1.5,
    area_population: 6061,
    center_lon: "113.324981",
    center_lat: "23.150597",
  },
  {
    id: 3,
    area_id: 2365,
    area_name: "广州市第一人民医院",
    area_shape: "圆形",
    round_radius: 1,
    area_population: 2715,
    center_lon: "113.256532",
    center_lat: "23.131213",
  },
  {
    id: 4,
    area_id: 9303,
    area_name: "中山大学附属第三医院",
    area_shape: "圆形",
    round_radius: 1,
    area_population: 2377,
    center_lon: "113.341211",
    center_lat: "23.134165",
  },
  {
    id: 5,
    area_id: 2577,
    area_name: "广州市珠江新城",
    area_shape: "圆形",
    round_radius: 2,
    area_population: 13392,
    center_lon: "113.325952",
    center_lat: "23.120005",
  },
  {
    id: 6,
    area_id: 1467,
    area_name: "广州白云工业园区",
    area_shape: "圆形",
    round_radius: 2,
    area_population: 47468,
    center_lon: "113.21523",
    center_lat: "23.271281",
  },
  {
    id: 7,
    area_id: 1938,
    area_name: "珠海金湾机场",
    area_shape: "圆形",
    round_radius: 1.5,
    area_population: 2460,
    center_lon: "113.38078",
    center_lat: "22.007695",
  },
  {
    id: 8,
    area_id: 8371,
    area_name: "中山大学附属第五医院",
    area_shape: "圆形",
    round_radius: 1,
    area_population: 1692,
    center_lon: "113.573455",
    center_lat: "22.298425",
  },
  {
    id: 9,
    area_id: 7781,
    area_name: "珠海金湾联港工业区",
    area_shape: "圆形",
    round_radius: 2.5,
    area_population: 68527,
    center_lon: "113.307093",
    center_lat: "22.099930",
  },
  {
    id: 10,
    area_id: 2517,
    area_name: "珠海香洲港",
    area_shape: "圆形",
    round_radius: 2,
    area_population: 1109,
    center_lon: "113.583803",
    center_lat: "22.289167",
  },
  {
    id: 11,
    area_id: 2843,
    area_name: "深圳宝安机场",
    area_shape: "圆形",
    round_radius: 2,
    area_population: 7112,
    center_lon: "113.814829",
    center_lat: "22.633092",
  },
  {
    id: 12,
    area_id: 4950,
    area_name: "深圳北站",
    area_shape: "圆形",
    round_radius: 1.5,
    area_population: 5576,
    center_lon: "114.0295",
    center_lat: "22.609875",
  },
  {
    id: 13,
    area_id: 5803,
    area_name: "深圳高新技术产业园区",
    area_shape: "圆形",
    round_radius: 5,
    area_population: 73648,
    center_lon: "113.944433",
    center_lat: "22.534537",
  },
];

export default hotPointsData;
