import "./index.scss";
import { Checkbox } from "antd";
import "antd/dist/antd.css";
import TrackCardImageURL from "../../../public/img/trackCard.png";
import TrackCardDImageURL from "../../../public/img/trackCardD.png";
import titleBgImageURL0 from "../../../public/img/titleBg0.png";
import titleBgImageURL1 from "../../../public/img/titleBg1.png";
import titleBgImageURL2 from "../../../public/img/titleBg2.png";
import titleBgImageURL3 from "../../../public/img/titleBg3.png";
import titleBgImageURL4 from "../../../public/img/titleBg4.png";
import titleBgImageURL5 from "../../../public/img/titleBg5.png";
const getStyles = (type) => {
  const contentContainerStyle =
    type === "enter"
      ? {
          backgroundImage: `url(${TrackCardDImageURL})`,
        }
      : {
          backgroundImage: `url(${TrackCardImageURL})`,
        };

  const resetButtonStyle =
    type === "enter"
      ? {
          // border: "1px solid #143893",
          // color: "#143893",
          // opacity: 0.65,
          cursor: "not-allowed",
        }
      : {
          // border: "1px solid #143893",
          // color: "#ffffff",
        };
  const itemTitleStyle = (index) => {
    const titleBgImages = {
      titleBgImageURL1,
      titleBgImageURL2,
      titleBgImageURL3,
      titleBgImageURL4,
      titleBgImageURL5,
      titleBgImageURL0,
    };
    return type === "enter"
      ? {
          marginLeft: "30px",
          backgroundImage: `url(${titleBgImages[`titleBgImageURL0`]})`,
        }
      : {
          backgroundImage: `url(${titleBgImages[`titleBgImageURL${index}`]})`,
        };
  };

  return {
    contentContainerStyle,
    resetButtonStyle,
    itemTitleStyle,
  };
};

export default function TrackCard({
  type,
  title,
  mobiles,
  onCheckboxChange,
  onClickTrackCardResetButton,
}) {
  const { contentContainerStyle, resetButtonStyle, itemTitleStyle } =
    getStyles(type);

  return (
    <>
      <div data-component="TrackCard">
        <div className="container">
          <div className="titleContainer">{title}</div>
          <div className="contentContainer" style={contentContainerStyle}>
            {mobiles &&
              mobiles.map((item, index) => {
                return (
                  <div className="itemTitleContainer" key={item.id}>
                    {type !== "enter" && (
                      <Checkbox
                        className="itemCheckbox"
                        checked={item.checked}
                        onChange={(event) =>
                          onCheckboxChange(event, item, "id")
                        }
                      ></Checkbox>
                    )}
                    <p
                      className="itemTitle noWrap"
                      title={item.mobile}
                      style={itemTitleStyle(index + 1)}
                    >
                      {item.mobile}
                    </p>
                  </div>
                );
              })}
            <div
              className="resetButton"
              style={resetButtonStyle}
              onClick={() => type !== "enter" && onClickTrackCardResetButton()}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
