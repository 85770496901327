import { useState, useEffect } from "react";
import ShadeLayer from "../../components/ShadeLayer";
import TrackEnter from "../../components/TrackEnter";
import Navigation from "../../components/Navigation";
import TrackCard from "../../components/TrackCard";
import MapControl from "../../components/MapControl";
import "./index.scss";
import { message } from "antd";
import cooperatorImgURL from "../../../public/img/cooperator.png";
import titleImgUrl from "../../../public/img/titleImg.png";
import trackImgUrl5 from "../../../public/img/tracka.png";
import trackImgUrl3 from "../../../public/img/trackb.png";
import trackImgUrl1 from "../../../public/img/trackc.png";
import trackImgUrl2 from "../../../public/img/trackd.png";
import trackImgUrl4 from "../../../public/img/tracke.png";
import cardsImgUrl1 from "../../../public/img/cards1.png";
import cardsImgUrl2 from "../../../public/img/cards2.png";
import cardsImgUrl3 from "../../../public/img/cards3.png";
import cardsImgUrl4 from "../../../public/img/cards4.png";
import cardsImgUrl5 from "../../../public/img/cards5.png";
import cardsPopImgUrl1 from "../../../public/img/cardsPop1.png";
import cardsPopImgUrl2 from "../../../public/img/cardsPop2.png";
import cardsPopImgUrl3 from "../../../public/img/cardsPop3.png";
import cardsPopImgUrl4 from "../../../public/img/cardsPop4.png";
import cardsPopImgUrl5 from "../../../public/img/cardsPop5.png";
import { Scene, LineLayer, Marker, MarkerLayer, Popup } from "@antv/l7";
import { GaodeMap } from "@antv/l7-maps";
import { trackData, trackDataGeo } from "../../../public/track";
import { Decrypt } from "../../helper/secretHelpers";
import { polling } from "../../service/api";
import { pathType, userDb, controlType } from "../../store/constant";
import { getParameters } from "../../helper/urlParseHelpers";
import { ChangeHourMinutesNumber } from "../../helper/parsetime";
import {
  parseTrackDatas,
  parseTrackDatasGeo,
} from "../../helper/parseTrackDatas";

const originTrackCardData = {
  nonTitle: "等待数据输入",
  preTitle: "数据隐私计算中",
  title: "已确认计算轨迹",
  mobiles: [],
};

const urlParams = getParameters(window.location.href);
originTrackCardData.mobiles =
  (urlParams.phone_numbers &&
    urlParams.phone_numbers.split(",") &&
    urlParams.phone_numbers.split(",").map((mobile, index) => {
      return {
        id: index,
        mobile,
        // mobileDecrypt: Decrypt(mobile.toUpperCase()),
        // mobileDecrypt: mobile,
        checked: index === 0 ? true : false,
      };
    })) ||
  [];

const trackNavData = {
  ...userDb.find((item) => {
    return item.user_id === urlParams.user_id;
  }),
  ...{
    title: "流动性轨迹隐私查询",
    titleImgUrl,
  },
};

const mapData = {
  scene: null,
  markerLayer: null,
  lineLayer: null,
};

let timeOut = null;
const timeOutTimeInterval = 7000 + Math.random() * 3000;

export default function Track({ history }) {
  document.title = trackNavData.title;

  let trackDataFilter = [];

  let trackDataGeoFilter = {
    type: "FeatureCollection",
    features: [],
  };

  const getFilterLayerDatas = (trackDataFilter, trackDataGeoFilter) => {
    trackData.forEach((item) => {
      mobiles.forEach((subItem, subIndex) => {
        if (item.msisdn.toString() === Decrypt(subItem.mobile.toUpperCase())) {
          item.index = subIndex + 1;
          item.checked = subItem.checked;
          trackDataFilter.push(item);
        }
      });
    });

    trackDataGeo.features.forEach((item) => {
      mobiles.forEach((subItem, subIndex) => {
        if (item.properties.msisdn === Decrypt(subItem.mobile.toUpperCase())) {
          item.properties.index = subIndex + 1;
          item.properties.checked = subItem.checked;
          trackDataGeoFilter.features.push(item);
        }
      });
    });
  };

  const getFilterLayerDatasChain = (trackDataFilter, trackDataGeoFilter) => {
    trackDataFilter.forEach((item) => {
      mobiles.forEach((subItem, subIndex) => {
        if (item.msisdn === subItem.mobile) {
          item.index = subIndex + 1;
          item.checked = subItem.checked;
        }
      });
    });

    trackDataGeoFilter.features.forEach((item) => {
      mobiles.forEach((subItem, subIndex) => {
        if (item.properties.msisdn === subItem.mobile) {
          item.properties.index = subIndex + 1;
          item.properties.checked = subItem.checked;
        }
      });
    });
  };

  const [mobiles, setMobiles] = useState(originTrackCardData.mobiles);
  const [isEnter, setIsEnter] = useState(true);
  const [markerDatas, setMarkerDatas] = useState([]);
  const [geoDatas, setGeoDatas] = useState([]);

  useEffect(() => {
    initDatas(history);
    return () => {
      timeOut && clearTimeout(timeOut);
    };
  }, []);

  const initDatas = (history) => {
    switch (history.location.pathname) {
      case pathType.track:
        getFilterLayerDatas(trackDataFilter, trackDataGeoFilter);
        timeOut = setTimeout(() => {
          setIsEnter(false);
          loadLayers(trackDataGeoFilter, trackDataFilter);
          loadScene(() => {
            mapData.scene.addLayer(mapData.lineLayer);
            mapData.scene.addMarkerLayer(mapData.markerLayer);
          });
        }, timeOutTimeInterval);
        break;
      case pathType.trackchain:
        // const testLoginAddress = "iaa15ammfjf6gh2f88x7nzpzagg53ny3hf5e6s4nc6";
        // const testTaskId =
        //   "4B40E590B877588CCAFDDA6300DBF58DF6CC738036C1296891BE2788A09771E00000000000000000000000000000000100000000000138D60000";

        polling("get", `/service/${urlParams.service_name}/psi/${urlParams.request_id}`, {
          login_address: urlParams.user_id,
        }).then((res) => {
          if (res.data.status && res.data.status === 4) {
            trackDataGeoFilter =
              parseTrackDatasGeo(res.data.service_output) || [];
            trackDataFilter = parseTrackDatas(res.data.service_output) || [];
            console.log(trackDataGeoFilter)
            console.log(trackDataFilter)
            setGeoDatas(parseTrackDatasGeo(res.data.service_output) || []);
            setMarkerDatas(parseTrackDatas(res.data.service_output) || []);
            setIsEnter(false);
            loadLayers(trackDataGeoFilter, trackDataFilter);
            loadScene(() => {
              mapData.scene.addLayer(mapData.lineLayer);
              mapData.scene.addMarkerLayer(mapData.markerLayer);
            });
          } else {
            message.error("status error");
          }
        });
        break;
      default:
        break;
    }
  };

  const loadLayers = (lineLayerSource, markerLayerDatas) => {
    // lineLayer
    mapData.lineLayer = new LineLayer({
      autoFit: true,
    });
    const lineLayerSourceChecked = {
      type: "FeatureCollection",
      features: lineLayerSource.features.filter((item) => {
        return item.properties.checked;
      }),
    };
    lineLayerSourceChecked &&
      mapData.lineLayer &&
      mapData.lineLayer
        .source(lineLayerSourceChecked)
        .size(4)
        .shape("line")
        .texture("02")
        .color("index", (propFromGeo) => {
          let color;
          switch (propFromGeo) {
            case 1:
              color = "#F29D1D";
              break;
            case 2:
              color = "#FF8CC6";
              break;
            case 3:
              color = "#00D07B";
              break;
            case 4:
              color = "#5CC8FF";
              break;
            case 5:
              color = "#DF2935";
              break;
            default:
              color = "#F29D1D";
              break;
          }
          return color;
        })
        .animate({
          interval: 1, // 间隔
          duration: 1, // 持续时间，延时
          trailLength: 2, // 流线长度
        })
        .style({
          lineTexture: true, // 开启线的贴图功能
          iconStep: 100, // 设置贴图纹理的间距
        });

    // markerLayer
    mapData.markerLayer = new MarkerLayer({
      bubble: true,
    });
    const markerLayerDatasChecked = markerLayerDatas.filter((item) => {
      return item.checked;
    });
    markerLayerDatasChecked &&
      mapData.markerLayer &&
      markerLayerDatasChecked.forEach((item) => {
        const el = document.createElement("div");
        el.className = "pointSize";

        const bottomCircle = document.createElement("div");
        bottomCircle.className = "bottomCircleSize";
        bottomCircle.style.opacity = item.close_contact / 500;

        const flagImg = document.createElement("img");
        flagImg.className = "flagSize";
        const trackImgUrls = {
          trackImgUrl1,
          trackImgUrl2,
          trackImgUrl3,
          trackImgUrl4,
          trackImgUrl5,
        };
        flagImg.src = trackImgUrls[`trackImgUrl${item.index}`];

        const msgContainer = document.createElement("div");
        msgContainer.className = "msgContainer";
        const cardsImgUrls = {
          cardsImgUrl1,
          cardsImgUrl2,
          cardsImgUrl3,
          cardsImgUrl4,
          cardsImgUrl5,
        };
        const cardImgUrl = cardsImgUrls[`cardsImgUrl${item.index}`];

        msgContainer.style.backgroundImage = `url(${cardImgUrl})`;

        const msgTitle = document.createElement("h2");
        msgTitle.className = "msgTitle";
        msgTitle.append(item.start_time);

        const msgText = document.createElement("p");
        msgText.className = "msgText";
        msgText.append(item.poi_name);
        msgContainer.appendChild(msgTitle);
        msgContainer.appendChild(msgText);

        el.appendChild(flagImg);
        el.appendChild(bottomCircle);
        el.appendChild(msgContainer);
        item.el = el;

        const cardsPopImgUrls = {
          cardsPopImgUrl1,
          cardsPopImgUrl2,
          cardsPopImgUrl3,
          cardsPopImgUrl4,
          cardsPopImgUrl5,
        };

        const cardPopImgUrl = cardsPopImgUrls[`cardsPopImgUrl${item.index}`];

        // <div class="pointSize" style="z-index: 3;">
        //   <div class="bottomCircleSize" style="opacity: ${item.close_contact / 150}"></div>
        //   <img class="flagSize" src="${trackImgUrls[`trackImgUrl${item.index}`]}"/>
        // </div>
        let html = `<div style="width: 100%; height: 100%; position: absolute;">
            <div class="popupContainer" style="background-image: url(${cardPopImgUrl});">
              <h2 class="popupTitle noWrap" title="${item.poi_name}">${
          item.poi_name
        }</h2>
              <div class="popupTextContainer">
                <span class="popupLabel">地点</span>
                <span class="popupText noWrap" title="${item.poi_name}">${
          item.poi_name
        }</span>
              </div>
              <div class="popupTextContainer">
                <span class="popupLabel">起始时间</span>
                <span class="popupText">${item.start_time}</span>
              </div>
              <div class="popupTextContainer">
                <span class="popupLabel">停留时长</span>
                <span class="popupText">${ChangeHourMinutesNumber(
                  item.duration
                )}</span>
              </div>
              <div class="popupTextContainer">
                <span class="popupLabel">密接人口数</span>
                <span class="popupText">${item.close_contact} 人</span>
              </div>
            </div>
            <div class="popupLayer"></div>
          </div>`;

        let popup = new Popup({
          offsets: [314, -90],
          closeButton: false,
          closeOnClick: true,
        });
        popup.setHTML(html);

        const marker = new Marker({
          extData: item,
          offsets: [100, -10],
        }).setLnglat([item.poi_lon, item.poi_lat]);
        marker.setElement(item.el);
        marker.setPopup(popup);
        marker.on("click", (e) => {
          // console.log(e);
        });
        mapData.markerLayer.addMarker(marker);
      });
  };

  const loadScene = (cb) => {
    if (!mapData.scene) {
      mapData.scene = new Scene({
        id: "trackMap",
        logoVisible: false,
        map: new GaodeMap({
          viewMode: "3D",
          center: [113.25, 23.1391],
          zoom: 15,
          minZoom: 8,
          pitch: 45,
          mapStyle: "amap://styles/c882f12907666fa9cef70720346ff585", // 样式URL
          token: "00955e409626a15a9f182f3f1f2334ce",
        }),
      });
      // 监听到地图加载完毕
      mapData.scene.on("loaded", () => {
        cb();
      });
    } else {
      cb();
    }
  };

  const reDrawLayer = () => {
    switch (history.location.pathname) {
      case pathType.track:
        trackDataFilter = [];
        trackDataGeoFilter = {
          type: "FeatureCollection",
          features: [],
        };
        getFilterLayerDatas(trackDataFilter, trackDataGeoFilter);
        break;
      case pathType.trackchain:
        trackDataGeoFilter = { ...geoDatas };
        trackDataFilter = [...markerDatas];
        getFilterLayerDatasChain(trackDataFilter, trackDataGeoFilter);
        break;
      default:
        break;
    }

    mapData.scene.removeLayer(mapData.markerLayer);
    mapData.scene.removeLayer(mapData.lineLayer);
    loadLayers(trackDataGeoFilter, trackDataFilter);
    loadScene(() => {
      mapData.scene.addLayer(mapData.lineLayer);
      mapData.scene.addMarkerLayer(mapData.markerLayer);
    });
  };

  const onCheckboxChange = (event, selectedItem, key) => {
    const copyMobiles = [...mobiles];
    copyMobiles.forEach((item) => {
      item.checked =
        item[key] === selectedItem[key] ? event.target.checked : item.checked;
    });
    setMobiles(copyMobiles);
    reDrawLayer();
  };

  const onClickTrackCardResetButton = () => {
    const copyMobiles = [...mobiles];
    copyMobiles.forEach((item, index) => {
      item.checked = index === 0;
    });
    setMobiles(copyMobiles);
    reDrawLayer();
  };

  const clickControlButton = (type) => {
    switch (type) {
      case controlType.d:
        if (mapData.scene.getPitch() === 45) {
          mapData.scene.setPitch(1);
        } else {
          mapData.scene.setPitch(45);
        }
        break;
      case controlType.reset:
        reDrawLayer();
        break;
      case controlType.zoomIn:
        mapData.scene && mapData.scene.zoomOut();
        break;
      case controlType.zoomOut:
        mapData.scene && mapData.scene.zoomIn();
        break;
      default:
        break;
    }
  };

  const goBackToHome = () => {
    window.open("https://stage.dbmp.bianjie.ai/servicesummary");
    // history.push("/");
  };

  return (
    <div data-component="Track" className="container">
      {isEnter && (
        <TrackEnter mobiles={mobiles} title={originTrackCardData.preTitle} />
      )}
      <Navigation data={trackNavData} goBackToHome={goBackToHome} />
      {!isEnter && (
        <TrackCard
          mobiles={mobiles}
          title={originTrackCardData.title}
          onCheckboxChange={onCheckboxChange}
          onClickTrackCardResetButton={onClickTrackCardResetButton}
        />
      )}
      {!isEnter && (
        <MapControl
          type="track"
          clickControlButton={clickControlButton}
        ></MapControl>
      )}
      <div id="trackMap" className="map"></div>
      <ShadeLayer type="map" />
      <img
        className="cooperatorLogos"
        src={cooperatorImgURL}
        alt="cooperator"
      ></img>
      {/* <img className="earthImg" src={earthImgUrl} alt="earthImg"></img> */}
      <div className="earthImg"></div>
    </div>
  );
}
