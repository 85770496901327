import { useEffect, useRef } from "react";

const useDidUpdateEffect = (fn, dps) => {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, dps);
};

export { useDidUpdateEffect }
