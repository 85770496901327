import "./index.scss";
import ShadeLayer from "../ShadeLayer";
import TrackCard from "../../components/TrackCard";
import DataStore from "../../components/DataStore";
import * as echarts from "echarts";
import loadOuterImgURL from "../../../public/img/loadOuter.png";
import loadOuterAnimateImgURL1 from "../../../public/img/loadOuter1.gif";
import loadOuterAnimateImgURL2 from "../../../public/img/loadOuter2.gif";
import loadOuterAnimateImgURL3 from "../../../public/img/loadOuter3.gif";
import loadOuterAnimateImgURL4 from "../../../public/img/loadOuter4.gif";
import loadOuterAnimateImgURL5 from "../../../public/img/loadOuter5.gif";
import loadBottomImgURL from "../../../public/img/loadBottom.png";
import { useEffect } from "react";
// import giphyImgURL from "../../../public/giphy.gif";

let giphy = null;

const getGiphy = () => {
  var chartDom = document.getElementById("giphy");
  var myChart = echarts.init(chartDom);
  var option;

  var data = [
    {
      fixed: true,
      x: myChart.getWidth() / 2,
      y: myChart.getHeight() / 2,
      symbolSize: 20,
      id: "-1",
    },
  ];

  var edges = [];

  option = {
    color: ["#69F0FF"],
    gradientColor: ["#69F0FF"],
    series: [
      {
        type: "graph",
        layout: "force",
        animation: false,
        data: data,
        force: {
          initLayout: "circular",
          // gravity: 0
          repulsion: 100,
          edgeLength: 5,
        },
        edges: edges,
      },
    ],
  };

  giphy = setInterval(() => {
    data.push({
      id: data.length,
    });
    var source = Math.round((data.length - 1) * Math.random());
    var target = Math.round((data.length - 1) * Math.random());
    if (source !== target) {
      edges.push({
        source: source,
        target: target,
      });
    }
    myChart.setOption({
      series: [
        {
          roam: true,
          data: data,
          edges: edges,
        },
      ],
    });
  }, 400);

  option && myChart.setOption(option);
};

export default function TrackEnter({ mobiles, title }) {
  const getLoadOuterAnimateImgURL = (length) => {
    const loadOuterAnimateImgURL = {
      loadOuterAnimateImgURL1,
      loadOuterAnimateImgURL2,
      loadOuterAnimateImgURL3,
      loadOuterAnimateImgURL4,
      loadOuterAnimateImgURL5,
    };
    return loadOuterAnimateImgURL[`loadOuterAnimateImgURL${length}`];
  };

  useEffect(() => {
    getGiphy();
    return () => {
      clearInterval(giphy);
    };
  }, []);

  return (
    <>
      <div data-component="TrackEnter">
        <div className="container">
          <ShadeLayer />
          <TrackCard type="enter" mobiles={mobiles} title={title} />
          <div className="loadContainer">
            <img
              className="loadOuterImg"
              src={loadOuterImgURL}
              alt="loadOuter"
            />
            <img
              className="loadOuterAnimateImg"
              src={getLoadOuterAnimateImgURL(mobiles.length)}
              alt="loadOuterAnimateImg"
            ></img>
            <img
              className="loadBottomImg"
              src={loadBottomImgURL}
              alt="loadBottom"
            />
            <div id="giphy" className="giphyImg"></div>
          </div>
          <DataStore />
        </div>
      </div>
    </>
  );
}
