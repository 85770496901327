const trackData = [
  {
    countrycode: 86,
    msisdn: 13962109892,
    start_time: "2021.7.22  8:21",
    duration: 0.46,
    poi_name: "荔海大厦",
    close_contact: 103,
    poi_lon: 113.24651,
    poi_lat: 23.142855,
  },
  {
    countrycode: 86,
    msisdn: 13962109892,
    start_time: "2021.7.22  9:15",
    duration: 0.16,
    poi_name: "纪念堂地铁站",
    close_contact: 352,
    poi_lon: 113.269794,
    poi_lat: 23.138402,
  },
  {
    countrycode: 86,
    msisdn: 13962109892,
    start_time: "2021.7.22  12:18",
    duration: 1.2,
    poi_name: "越秀山体育场",
    close_contact: 2523,
    poi_lon: 113.276442,
    poi_lat: 23.133184,
  },
  {
    countrycode: 86,
    msisdn: 13962109892,
    start_time: "2021.7.22  16:20",
    duration: 0.68,
    poi_name: "湘鸿酒店",
    close_contact: 121,
    poi_lon: 113.281131,
    poi_lat: 23.147058,
  },
  {
    countrycode: 86,
    msisdn: 18871298341,
    start_time: "2021.7.22  10:45",
    duration: 1.1,
    poi_name: "广交会展馆",
    close_contact: 8532,
    poi_lon: 113.371186,
    poi_lat: 23.104276,
  },
  {
    countrycode: 86,
    msisdn: 18871298341,
    start_time: "2021.7.22  12:30",
    duration: 0.5,
    poi_name: "保利金融大都会",
    close_contact: 309,
    poi_lon: 113.389942,
    poi_lat: 23.123156,
  },
  {
    countrycode: 86,
    msisdn: 18871298341,
    start_time: "2021.7.22  13:20",
    duration: 2.3,
    poi_name: "高星运动娱乐中心",
    close_contact: 148,
    poi_lon: 113.384193,
    poi_lat: 23.150938,
  },
  {
    countrycode: 86,
    msisdn: 13789271261,
    start_time: "2021.7.22  9:20",
    duration: 0.4,
    poi_name: "越秀公园",
    close_contact: 141,
    poi_lon: 113.272875,
    poi_lat: 23.146751,
  },
  {
    countrycode: 86,
    msisdn: 13789271261,
    start_time: "2021.7.22  10:48",
    duration: 1.6,
    poi_name: "中山纪念堂",
    close_contact: 164,
    poi_lon: 113.271725,
    poi_lat: 23.139839,
  },
  {
    countrycode: 86,
    msisdn: 13789271261,
    start_time: "2021.7.22  13:40",
    duration: 0.6,
    poi_name: "地王广场",
    close_contact: 145,
    poi_lon: 113.290698,
    poi_lat: 23.131996,
  },
  {
    countrycode: 86,
    msisdn: 13789271261,
    start_time: "2021.7.22  14:25",
    duration: 1.8,
    poi_name: "广州动物园",
    close_contact: 280,
    poi_lon: 113.312113,
    poi_lat: 23.147549,
  },
  {
    countrycode: 86,
    msisdn: 13789271261,
    start_time: "2021.7.22  16:20",
    duration: 0.67,
    poi_name: "广州东站",
    close_contact: 1265,
    poi_lon: 113.331229,
    poi_lat: 23.156055,
  },
  {
    countrycode: 86,
    msisdn: 15891898129,
    start_time: "2021.7.22  8:45",
    duration: 0.17,
    poi_name: "合生广场",
    close_contact: 325,
    poi_lon: 113.32045,
    poi_lat: 23.092242,
  },
  {
    countrycode: 86,
    msisdn: 15891898129,
    start_time: "2021.7.22  9:50",
    duration: 0.21,
    poi_name: "大塘地铁站",
    close_contact: 368,
    poi_lon: 113.327995,
    poi_lat: 23.083532,
  },
  {
    countrycode: 86,
    msisdn: 15891898129,
    start_time: "2021.7.22  12:40",
    duration: 0.6,
    poi_name: "珠江公园",
    close_contact: 84,
    poi_lon: 113.345961,
    poi_lat: 23.125814,
  },
  {
    countrycode: 86,
    msisdn: 15891898129,
    start_time: "2021.7.22  13:55",
    duration: 2.2,
    poi_name: "华南师范大学（石牌校区）",
    close_contact: 329,
    poi_lon: 113.357029,
    poi_lat: 23.144757,
  },
  {
    countrycode: 86,
    msisdn: 15909102654,
    start_time: "2021.7.22  9:12",
    duration: 0.3,
    poi_name: "星辉韵寓",
    close_contact: 78,
    poi_lon: 113.379917,
    poi_lat: 23.139939,
  },
  {
    countrycode: 86,
    msisdn: 15909102654,
    start_time: "2021.7.22  10:55",
    duration: 0.19,
    poi_name: "天府路小学",
    close_contact: 145,
    poi_lon: 113.36806,
    poi_lat: 23.13841,
  },
  {
    countrycode: 86,
    msisdn: 15909102654,
    start_time: "2021.7.22  11:50",
    duration: 1.5,
    poi_name: "华骏花园",
    close_contact: 94,
    poi_lon: 113.348953,
    poi_lat: 23.128872,
  },
  {
    countrycode: 86,
    msisdn: 13802987364,
    start_time: "2021.7.22  9:05",
    duration: 0.5,
    poi_name: "海景阁",
    close_contact: 89,
    poi_lon: 113.296456,
    poi_lat: 23.109993,
  },
  {
    countrycode: 86,
    msisdn: 13802987364,
    start_time: "2021.7.22  10:30",
    duration: 0.19,
    poi_name: "广州市水上运动管理中心",
    close_contact: 76,
    poi_lon: 113.304936,
    poi_lat: 23.109661,
  },
  {
    countrycode: 86,
    msisdn: 13802987364,
    start_time: "2021.7.22  11:25",
    duration: 0.3,
    poi_name: "中国图书大厦",
    close_contact: 128,
    poi_lon: 113.317764,
    poi_lat: 23.104442,
  },
  {
    countrycode: 86,
    msisdn: 13802987364,
    start_time: "2021.7.22  12:50",
    duration: 0.32,
    poi_name: "广州市第六中学",
    close_contact: 213,
    poi_lon: 113.313739,
    poi_lat: 23.102913,
  },
  {
    countrycode: 86,
    msisdn: 13802987364,
    start_time: "2021.7.22  14:20",
    duration: 0.9,
    poi_name: "康怡苑",
    close_contact: 137,
    poi_lon: 113.30914,
    poi_lat: 23.097196,
  },
  {
    countrycode: 86,
    msisdn: 13557937517,
    start_time: "2021.7.22  8:20",
    duration: 0.23,
    poi_name: "花城大道地铁站",
    close_contact: 542,
    poi_lon: 113.331346,
    poi_lat: 23.124984,
  },
  {
    countrycode: 86,
    msisdn: 13557937517,
    start_time: "2021.7.22  9:24",
    duration: 1.4,
    poi_name: "广东省博物院",
    close_contact: 174,
    poi_lon: 113.332963,
    poi_lat: 23.12053,
  },
  {
    countrycode: 86,
    msisdn: 13557937517,
    start_time: "2021.7.22  12:50",
    duration: 1.2,
    poi_name: "广州塔南广场",
    close_contact: 229,
    poi_lon: 113.33113,
    poi_lat: 23.111921,
  },
  {
    countrycode: 86,
    msisdn: 13989109206,
    start_time: "2021.7.22  8:40",
    duration: 0.63,
    poi_name: "海珠客运站",
    close_contact: 518,
    poi_lon: 113.305978,
    poi_lat: 23.069302,
  },
  {
    countrycode: 86,
    msisdn: 13989109206,
    start_time: "2021.7.22  10:25",
    duration: 0.45,
    poi_name: "瑞宝制衣城",
    close_contact: 164,
    poi_lon: 113.3049,
    poi_lat: 23.083266,
  },
  {
    countrycode: 86,
    msisdn: 13989109206,
    start_time: "2021.7.22  11:50",
    duration: 0.56,
    poi_name: "珠江针织大厦",
    close_contact: 78,
    poi_lon: 113.306122,
    poi_lat: 23.089018,
  },
  {
    countrycode: 86,
    msisdn: 13989109206,
    start_time: "2021.7.22  12:50",
    duration: 0.32,
    poi_name: "万达广场",
    close_contact: 123,
    poi_lon: 113.32071,
    poi_lat: 23.089483,
  },
  {
    countrycode: 86,
    msisdn: 13989109206,
    start_time: "2021.7.22  13:50",
    duration: 2.1,
    poi_name: "聚隆综合市场",
    close_contact: 385,
    poi_lon: 113.331921,
    poi_lat: 23.085693,
  },
  {
    countrycode: 86,
    msisdn: 13676912984,
    start_time: "2021.7.22  8:30",
    duration: 0.14,
    poi_name: "岗顶地铁站",
    close_contact: 268,
    poi_lon: 113.346294,
    poi_lat: 23.14057,
  },
  {
    countrycode: 86,
    msisdn: 13676912984,
    start_time: "2021.7.22  10:55",
    duration: 1.5,
    poi_name: "人保大厦",
    close_contact: 169,
    poi_lon: 113.321141,
    poi_lat: 23.132129,
  },
  {
    countrycode: 86,
    msisdn: 13676912984,
    start_time: "2021.7.22  11:40",
    duration: 2.2,
    poi_name: "广东美术馆",
    close_contact: 137,
    poi_lon: 113.314099,
    poi_lat: 23.114182,
  },
  {
    countrycode: 86,
    msisdn: 13887128658,
    start_time: "2021.7.22  10:30",
    duration: 0.81,
    poi_name: "正佳广场",
    close_contact: 164,
    poi_lon: 113.333466,
    poi_lat: 23.137878,
  },
  {
    countrycode: 86,
    msisdn: 13887128658,
    start_time: "2021.7.22  12:50",
    duration: 0.21,
    poi_name: "黄埔大道地铁站",
    close_contact: 251,
    poi_lon: 113.330987,
    poi_lat: 23.132627,
  },
  {
    countrycode: 86,
    msisdn: 13887128658,
    start_time: "2021.7.22  14:40",
    duration: 1.2,
    poi_name: "利雅湾",
    close_contact: 87,
    poi_lon: 113.335011,
    poi_lat: 23.12166,
  },
  {
    countrycode: 86,
    msisdn: 13887128658,
    start_time: "2021.7.22  16:20",
    duration: 2.2,
    poi_name: "广州大剧院",
    close_contact: 361,
    poi_lon: 113.329352,
    poi_lat: 23.120796,
  },
  {
    countrycode: 86,
    msisdn: "13432266140",
    start_time: "2021.7.22  8:00",
    duration: 0.86,
    poi_name: "广州香格里拉大酒店",
    close_contact: 48,
    poi_lon: 113.370265953729,
    poi_lat: 23.1044327905474,
  },
  {
    countrycode: 86,
    msisdn: "13432266140",
    start_time: "2021.7.22  9:00",
    duration: 4.8,
    poi_name: "广州国际会展中心",
    close_contact: 1289,
    poi_lon: 113.362191994198,
    poi_lat: 23.1023702418408,
  },
  {
    countrycode: 86,
    msisdn: "13432266140",
    start_time: "2021.7.22  16:00",
    duration: 1,
    poi_name: "花城广场",
    close_contact: 130,
    poi_lon: 113.325044558896,
    poi_lat: 23.1203763937783,
  },
  {
    countrycode: 86,
    msisdn: "13432266140",
    start_time: "2021.7.22  17:23",
    duration: 1.82,
    poi_name: "活利仕西餐厅",
    close_contact: 30,
    poi_lon: 113.329429123179,
    poi_lat: 23.1209375362775,
  },
  {
    countrycode: 86,
    msisdn: "13516808555",
    start_time: "2021.7.22  8:20",
    duration: 0.3,
    poi_name: "体育西路总站",
    close_contact: 98,
    poi_lon: 113.321786985893,
    poi_lat: 23.1343501274452,
  },
  {
    countrycode: 86,
    msisdn: "13516808555",
    start_time: "2021.7.22  9:00",
    duration: 2.5,
    poi_name: "广州购书中心读者俱乐部",
    close_contact: 60,
    poi_lon: 113.320586813455,
    poi_lat: 23.1344555357858,
  },
  {
    countrycode: 86,
    msisdn: "13516808555",
    start_time: "2021.7.22  13:25",
    duration: 4.5,
    poi_name: "天河城百货",
    close_contact: 150,
    poi_lon: 113.322473136259,
    poi_lat: 23.1322097950189,
  },
  {
    countrycode: 86,
    msisdn: "13820045163",
    start_time: "2021.7.22  8:21",
    duration: 2,
    poi_name: "恒大酒店",
    close_contact: 124,
    poi_lon: 113.204509867356,
    poi_lat: 23.1920523909194,
  },
  {
    countrycode: 86,
    msisdn: "13820045163",
    start_time: "2021.7.22  12:00",
    duration: 3,
    poi_name: "广州K11购物艺术中心",
    close_contact: 200,
    poi_lon: 113.327039808844,
    poi_lat: 23.1200084128987,
  },
  {
    countrycode: 86,
    msisdn: "13820045163",
    start_time: "2021.7.22  16:30",
    duration: 2,
    poi_name: "天河体育中心篮球城",
    close_contact: 156,
    poi_lon: 113.327867114552,
    poi_lat: 23.139152668154,
  },
  {
    countrycode: 86,
    msisdn: "13631631729",
    start_time: "2021.7.22  8:00",
    duration: 0.5,
    poi_name: "锦龙社区",
    close_contact: 30,
    poi_lon: 113.248734540226,
    poi_lat: 23.1252670537269,
  },
  {
    countrycode: 86,
    msisdn: "13631631729",
    start_time: "2021.7.22  9:00",
    duration: 2,
    poi_name: "又一间茶点轩",
    close_contact: 60,
    poi_lon: 113.263932909412,
    poi_lat: 23.1251085257596,
  },
  {
    countrycode: 86,
    msisdn: "13631631729",
    start_time: "2021.7.22  12:00",
    duration: 4,
    poi_name: "人民公园",
    close_contact: 291,
    poi_lon: 113.264339084657,
    poi_lat: 23.1271423333094,
  },
  {
    countrycode: 86,
    msisdn: "13631631729",
    start_time: "2021.7.22  17:00",
    duration: 1,
    poi_name: "锦龙社区",
    close_contact: 40,
    poi_lon: 113.248734540226,
    poi_lat: 23.1252670537269,
  },
  {
    countrycode: 86,
    msisdn: "13991268031",
    start_time: "2021.7.22  8:00",
    duration: 1.5,
    poi_name: "又一间茶点轩",
    close_contact: 35,
    poi_lon: 113.248734540226,
    poi_lat: 23.1252670537269,
  },
  {
    countrycode: 86,
    msisdn: "13991268031",
    start_time: "2021.7.22  10:00",
    duration: 0.8,
    poi_name: "龙津市场",
    close_contact: 60,
    poi_lon: 113.244003041675,
    poi_lat: 23.1241443076901,
  },
  {
    countrycode: 86,
    msisdn: "13991268031",
    start_time: "2021.7.22  11:00",
    duration: 7,
    poi_name: "锦龙社区",
    close_contact: 30,
    poi_lon: 113.248734540226,
    poi_lat: 23.1252670537269,
  },
  {
    countrycode: 86,
    msisdn: "13795712881",
    start_time: "2021.7.22  8:00",
    duration: 1,
    poi_name: "又一间茶点轩",
    close_contact: 50,
    poi_lon: 113.263932909412,
    poi_lat: 23.1251085257596,
  },
  {
    countrycode: 86,
    msisdn: "13795712881",
    start_time: "2021.7.22  9:20",
    duration: 0.8,
    poi_name: "龙津市场",
    close_contact: 210,
    poi_lon: 113.244003041675,
    poi_lat: 23.1241443076901,
  },
  {
    countrycode: 86,
    msisdn: "13795712881",
    start_time: "2021.7.22  11:00",
    duration: 3,
    poi_name: "幸福老人活动中心",
    close_contact: 40,
    poi_lon: 113.243259703246,
    poi_lat: 23.1231428058698,
  },
  {
    countrycode: 86,
    msisdn: "13795712881",
    start_time: "2021.7.22  15:00",
    duration: 3,
    poi_name: "南濠街小区",
    close_contact: 80,
    poi_lon: 113.266871204397,
    poi_lat: 23.128551048796,
  },
  {
    countrycode: 86,
    msisdn: "13578261957",
    start_time: "2021.7.22  8:00",
    duration: 0.2,
    poi_name: "汇鑫阁",
    close_contact: 30,
    poi_lon: 113.248505389092,
    poi_lat: 23.124298171638,
  },
  {
    countrycode: 86,
    msisdn: "13578261957",
    start_time: "2021.7.22  9:00",
    duration: 8,
    poi_name: "越富广场",
    close_contact: 400,
    poi_lon: 113.254572889151,
    poi_lat: 23.130082464032,
  },
  {
    countrycode: 86,
    msisdn: "13578261957",
    start_time: "2021.7.22  18:00",
    duration: 2,
    poi_name: "悦苑餐厅",
    close_contact: 40,
    poi_lon: 113.260473250005,
    poi_lat: 23.1235985172138,
  },
  {
    countrycode: 86,
    msisdn: "13938709285",
    start_time: "2021.7.22  8:00",
    duration: 0.5,
    poi_name: "鹤园小区",
    close_contact: 40,
    poi_lon: 113.24094958311,
    poi_lat: 23.0737449356155,
  },
  {
    countrycode: 86,
    msisdn: "13938709285",
    start_time: "2021.7.22  11:00",
    duration: 3,
    poi_name: "广州胜恩贸易商行",
    close_contact: 30,
    poi_lon: 113.231078504176,
    poi_lat: 23.0724269699614,
  },
  {
    countrycode: 86,
    msisdn: "13938709285",
    start_time: "2021.7.22  15:00",
    duration: 2,
    poi_name: "广船医院鹤园门诊",
    close_contact: 60,
    poi_lon: 113.23946551984,
    poi_lat: 23.074204436933,
  },
  {
    countrycode: 86,
    msisdn: "13938227135",
    start_time: "2021.7.22  8:21",
    duration: 2.16,
    poi_name: "广州医科大学附属第一医院",
    close_contact: 120,
    poi_lon: 113.262100718566,
    poi_lat: 23.1127341218195,
  },
  {
    countrycode: 86,
    msisdn: "13938227135",
    start_time: "2021.7.22  11:30",
    duration: 1.5,
    poi_name: "百回味茶餐厅",
    close_contact: 60,
    poi_lon: 113.261371490636,
    poi_lat: 23.1132454681856,
  },
  {
    countrycode: 86,
    msisdn: "13938227135",
    start_time: "2021.7.22  15:30",
    duration: 3,
    poi_name: "德宝交易广场",
    close_contact: 70,
    poi_lon: 113.261239226733,
    poi_lat: 23.1140174043302,
  },
  {
    countrycode: 86,
    msisdn: "13750367246",
    start_time: "2021.7.22  8:20",
    duration: 1,
    poi_name: "锦城花园",
    close_contact: 10,
    poi_lon: 113.309344851301,
    poi_lat: 23.1308105048023,
  },
  {
    countrycode: 86,
    msisdn: "13750367246",
    start_time: "2021.7.22  10:00",
    duration: 7.5,
    poi_name: "广州铁路大厦",
    close_contact: 100,
    poi_lon: 113.300485542245,
    poi_lat: 23.1246287369049,
  },
  {
    countrycode: 86,
    msisdn: "13750367246",
    start_time: "2021.7.22  18:00",
    duration: 2,
    poi_name: "花城广场",
    close_contact: 120,
    poi_lon: 113.325044558896,
    poi_lat: 23.1203763937783,
  },
];

const trackDataGeo = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        msisdn: "13962109892",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.24651, 23.142855],
            [113.269794, 23.138402],
            [113.276442, 23.133184],
            [113.281131, 23.147058]
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "18871298341",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.371186, 23.104276],
            [113.389942, 23.123156],
            [113.384193, 23.150938],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13789271261",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.272875, 23.146751],
            [113.271725, 23.139839],
            [113.290698, 23.131996],
            [113.312113, 23.147549],
            [113.331229, 23.156055],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "15891898129",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.32045, 23.092242],
            [113.327995, 23.083532],
            [113.345961, 23.125814],
            [113.357029, 23.144757],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "15909102654",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.379917, 23.139939],
            [113.36806, 23.13841],
            [113.348953, 23.128872],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13802987364",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.296456, 23.109993],
            [113.304936, 23.109661],
            [113.317764, 23.104442],
            [113.313739, 23.102913],
            [113.30914, 23.097196],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13557937517",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.331346, 23.124984],
            [113.332963, 23.12053],
            [113.33113, 23.111921],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13989109206",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.305978, 23.069302],
            [113.3049, 23.083266],
            [113.306122, 23.089018],
            [113.32071, 23.089483],
            [113.331921, 23.085693],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13676912984",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.346294, 23.14057],
            [113.321141, 23.132129],
            [113.314099, 23.114182],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13887128658",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.333466, 23.137878],
            [113.330987, 23.132627],
            [113.335011, 23.12166],
            [113.329352, 23.120796],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13432266140",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.370265953729, 23.1044327905474],
            [113.362191994198, 23.1023702418408],
            [113.325044558896, 23.1203763937783],
            [113.329429123179, 23.1209375362775],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13516808555",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.321786985893, 23.1343501274452],
            [113.320586813455, 23.1344555357858],
            [113.322473136259, 23.1322097950189],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13820045163",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.204509867356, 23.1920523909194],
            [113.327039808844, 23.1200084128987],
            [113.327867114552, 23.139152668154],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13631631729",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.248734540226, 23.1252670537269],
            [113.263932909412, 23.1251085257596],
            [113.264339084657, 23.1271423333094],
            [113.248734540226, 23.1252670537269],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13991268031",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.248734540226, 23.1252670537269],
            [113.244003041675, 23.1241443076901],
            [113.248734540226, 23.1252670537269],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13795712881",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.263932909412, 23.1251085257596],
            [113.244003041675, 23.1241443076901],
            [113.243259703246, 23.1231428058698],
            [113.266871204397, 23.128551048796],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13578261957",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.248505389092, 23.124298171638],
            [113.254572889151, 23.130082464032],
            [113.260473250005, 23.1235985172138],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13938709285",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.24094958311, 23.0737449356155],
            [113.231078504176, 23.0724269699614],
            [113.23946551984, 23.074204436933],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13938227135",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.262100718566, 23.1127341218195],
            [113.261371490636, 23.1132454681856],
            [113.261239226733, 23.1140174043302],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        msisdn: "13750367246",
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [113.309344851301, 23.1308105048023],
            [113.300485542245, 23.1246287369049],
            [113.325044558896, 23.1203763937783],
          ],
        ],
      },
    },
  ],
};

export { trackData, trackDataGeo };
