const isRangeIn = (str, maxnum, minnum) => {
  const num = parseFloat(str);
  if (num <= maxnum && num >= minnum) {
    return true;
  }
  return false;
};

export {
  isRangeIn,
};
