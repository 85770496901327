import { useEffect, useState } from "react";
import "./index.scss";
import { getRestString } from "../../helper/parseString";
import responseServiceLineImageURL1 from "../../../public/img/responseServiceLine.png";
import responseServiceLineImageURL2 from "../../../public/img/responseServiceLine2.png";
import responseServiceLineImageURL3 from "../../../public/img/responseServiceLine3.png";
import responseServiceLineImageURL4 from "../../../public/img/responseServiceLine4.png";
import responseServiceAreaCardImageURL1 from "../../../public/img/responseServiceAreaCard1.png";
import responseServiceAreaCardImageURL2 from "../../../public/img/responseServiceAreaCard2.png";
import responseServiceAreaCardImageURL3 from "../../../public/img/responseServiceAreaCard3.png";
import responseServiceAreaCardImageURL4 from "../../../public/img/responseServiceAreaCard4.png";
import responseServiceCountCardImageURL1 from "../../../public/img/responseServiceCountCard1.png";
import responseServiceCountCardImageURL2 from "../../../public/img/responseServiceCountCard2.png";
import responseServiceCountCardImageURL3 from "../../../public/img/responseServiceCountCard3.png";
import responseServiceCountCardImageURL4 from "../../../public/img/responseServiceCountCard4.png";
import arrImageURL1 from "../../../public/img/arr1.png";
import arrImageURL2 from "../../../public/img/arr2.png";
import arrImageURL3 from "../../../public/img/arr3.png";
import arrImageURL4 from "../../../public/img/arr4.png";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import { getLocalTime } from "../../helper/parsetime";

const noDataPlaceHolder = "----";

// 获取动态style
const getStyles = (type) => {
  const labelStyle =
    type === "right"
      ? {
          letterSpacing: "5px",
          marginLeft: "120px",
        }
      : {
          letterSpacing: "5px",
          marginLeft: "-326px",
        };

  const contentItemStyle = (index) => {
    const responseServiceLineImage = {
      responseServiceLineImageURL1,
      responseServiceLineImageURL2,
      responseServiceLineImageURL3,
      responseServiceLineImageURL4,
    };

    return {
      backgroundImage: `url(${
        responseServiceLineImage[`responseServiceLineImageURL${index}`]
      })`,
    };
  };

  const itemValueStyle = (index) => {
    const colors = ["#FF7606", "#FCEE21", "#08D438", "#999999"];
    return {
      color: colors[index],
    };
  };

  const arrImageStyle =
    type === "right"
      ? {
          marginLeft: "30px",
        }
      : {};

  const containerStyle =
    type === "right"
      ? {
          right: "82px",
          bottom: "90px",
          width: "430px",
          height: "480px",
        }
      : {
          left: "84px",
          bottom: "20px",
          width: "428px",
          height: "552px",
        };

  const contentContainerStyle =
    type === "right"
      ? {
          margin: "0 70px",
          height: "400px",
        }
      : {
          margin: "0 30px",
          height: "460px",
        };

  const carouselLeftStyle = (index) => {
    const responseServiceAreaCardImage = {
      responseServiceAreaCardImageURL1,
      responseServiceAreaCardImageURL2,
      responseServiceAreaCardImageURL3,
      responseServiceAreaCardImageURL4,
    };
    return {
      backgroundImage: `url(${
        responseServiceAreaCardImage[`responseServiceAreaCardImageURL${index}`]
      })`,
    };
  };

  const carouselRightStyle = (index) => {
    const responseServiceCountCardImage = {
      responseServiceCountCardImageURL1,
      responseServiceCountCardImageURL2,
      responseServiceCountCardImageURL3,
      responseServiceCountCardImageURL4,
    };
    return {
      backgroundImage: `url(${
        responseServiceCountCardImage[
          `responseServiceCountCardImageURL${index}`
        ]
      })`,
    };
  };

  const carouselStyle =
    type === "right" ? carouselRightStyle : carouselLeftStyle;

  return {
    contentContainerStyle,
    containerStyle,
    contentItemStyle,
    carouselStyle,
    itemValueStyle,
    labelStyle,
    arrImageStyle,
  };
};

// 获取动态图片地址
const arrImageIconPath = {
  arrImageURL1,
  arrImageURL2,
  arrImageURL3,
  arrImageURL4,
};

SwiperCore.use([Pagination]);

export default function ResponseServiceAreaCard({
  type,
  data,
  exData,
  hotPointsDataFilter,
  selectedPoint,
  clickDecryptButton,
  cardMouseEnter,
  cardMouseLeave,
}) {
  const {
    contentContainerStyle,
    containerStyle,
    contentItemStyle,
    carouselStyle,
    itemValueStyle,
    labelStyle,
    arrImageStyle,
  } = getStyles(type);

  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    selectedPoint && swiper && swiper.slideTo(Number(selectedPoint.index));
  }, [selectedPoint, hotPointsDataFilter]);

  return (
    <>
      {/* split css */}
      <div data-component="ResponseServiceCard">
        <div
          className="container"
          style={containerStyle}
          onMouseEnter={(e) => type === "right" && cardMouseEnter(e)}
          onMouseLeave={(e) => type === "right" && cardMouseLeave(e)}
        >
          <img
            className="arrImage"
            src={
              selectedPoint
                ? arrImageIconPath[`arrImageURL${selectedPoint.cardColor}`]
                : arrImageIconPath[`arrImageURL4`]
            }
            style={arrImageStyle}
            alt="arr"
          ></img>
          <h2 className="label noWrap labelPosition" style={labelStyle}>
            服务响应结果
          </h2>
          <Swiper
            className="carouselContainer"
            onSwiper={(swipper) => {
              setSwiper(swipper);
            }}
            style={
              selectedPoint
                ? carouselStyle(selectedPoint.cardColor)
                : carouselStyle(4)
            }
            simulateTouch={false}
            pagination={
              type === "left"
                ? {
                    clickable: false,
                  }
                : false
            }
            observer={true}
          >
            {hotPointsDataFilter &&
              hotPointsDataFilter.map((item, index) => {
                return (
                  item && (
                    <SwiperSlide
                      key={item.area_id + index}
                      style={{ width: "100%", height: "100%" }}
                    >
                      {type === "right" && !item.isDecrypt && (
                        <div
                          className="decryptButton"
                          onClick={() => clickDecryptButton(item)}
                        ></div>
                      )}
                      {/* <img
                        className="arrImage"
                        src={arrImageIconPath[`arrImageURL${item.cardColor}`]}
                        style={arrImageStyle}
                        alt="arr"
                      ></img> */}
                      <div
                        className="carouselContainer"
                        // style={carouselStyle(item.cardColor)}
                      >
                        <div
                          className="contentContainer"
                          style={contentContainerStyle}
                        >
                          {type === "left" && (
                            <>
                              <div
                                className="contentItem"
                                style={
                                  selectedPoint
                                    ? contentItemStyle(item.cardColor)
                                    : contentItemStyle(4)
                                }
                              >
                                <label className="itemLabel noWrap">
                                  区域ID
                                </label>
                                <span
                                  className="itemValue noWrap"
                                  style={
                                    selectedPoint
                                      ? itemValueStyle(item.cardColor - 1)
                                      : itemValueStyle(4 - 1)
                                  }
                                >
                                  {selectedPoint
                                    ? item.area_id
                                    : noDataPlaceHolder}
                                </span>
                              </div>
                              <div
                                className="contentItem"
                                style={
                                  selectedPoint
                                    ? contentItemStyle(item.cardColor)
                                    : contentItemStyle(4)
                                }
                              >
                                <label className="itemLabel noWrap">
                                  中心地名
                                </label>
                                <span
                                  className="itemValue noWrap"
                                  style={
                                    selectedPoint
                                      ? itemValueStyle(item.cardColor - 1)
                                      : itemValueStyle(4 - 1)
                                  }
                                >
                                  {selectedPoint
                                    ? item.area_name
                                    : noDataPlaceHolder}
                                </span>
                              </div>
                              <div
                                className="contentItem"
                                style={
                                  selectedPoint
                                    ? contentItemStyle(item.cardColor)
                                    : contentItemStyle(4)
                                }
                              >
                                <label className="itemLabel noWrap">
                                  区域形状
                                </label>
                                <span
                                  className="itemValue noWrap"
                                  style={
                                    selectedPoint
                                      ? itemValueStyle(item.cardColor - 1)
                                      : itemValueStyle(4 - 1)
                                  }
                                >
                                  {selectedPoint
                                    ? item.area_shape
                                    : noDataPlaceHolder}
                                </span>
                              </div>
                              <div
                                className="contentItem"
                                style={
                                  selectedPoint
                                    ? contentItemStyle(item.cardColor)
                                    : contentItemStyle(4)
                                }
                              >
                                <label className="itemLabel noWrap">
                                  区域半径
                                </label>
                                <span
                                  className="itemValue noWrap"
                                  style={
                                    selectedPoint
                                      ? itemValueStyle(item.cardColor - 1)
                                      : itemValueStyle(4 - 1)
                                  }
                                >
                                  {selectedPoint
                                    ? item.round_radius + " km"
                                    : noDataPlaceHolder}
                                </span>
                              </div>
                            </>
                          )}
                          {type === "right" && (
                            <>
                              <div
                                className="contentItem"
                                style={
                                  selectedPoint
                                    ? contentItemStyle(item.cardColor)
                                    : contentItemStyle(4)
                                }
                              >
                                <label className="itemLabel noWrap">
                                  查询人
                                </label>
                                <span
                                  className="itemValue noWrap"
                                  style={
                                    selectedPoint
                                      ? itemValueStyle(item.cardColor - 1)
                                      : itemValueStyle(4 - 1)
                                  }
                                >
                                  {selectedPoint
                                    ? data.consumer_name
                                    : noDataPlaceHolder}
                                </span>
                              </div>
                              <div
                                className="contentItem"
                                style={
                                  selectedPoint
                                    ? contentItemStyle(item.cardColor)
                                    : contentItemStyle(4)
                                }
                              >
                                <label className="itemLabel noWrap">
                                  查询时间
                                </label>
                                <span
                                  className="itemValue noWrap"
                                  style={
                                    selectedPoint
                                      ? itemValueStyle(item.cardColor - 1)
                                      : itemValueStyle(4 - 1)
                                  }
                                >
                                  {selectedPoint
                                    ? data &&
                                      data.req_tx_info &&
                                      getLocalTime(data.req_tx_info.tx_time)
                                    : noDataPlaceHolder}
                                </span>
                              </div>
                              <div
                                className="contentItem"
                                style={
                                  selectedPoint
                                    ? contentItemStyle(item.cardColor)
                                    : contentItemStyle(4)
                                }
                              >
                                <label className="itemLabel noWrap">
                                  查询结果
                                </label>
                                <span
                                  className="itemValue noWrap"
                                  style={
                                    selectedPoint
                                      ? itemValueStyle(item.cardColor - 1)
                                      : itemValueStyle(4 - 1)
                                  }
                                >
                                  {item.isDecrypt
                                    ? `${item.area_population} 人`
                                    : "****"}
                                </span>
                              </div>
                            </>
                          )}
                          <div
                            className="contentItem"
                            style={
                              selectedPoint
                                ? contentItemStyle(item.cardColor)
                                : contentItemStyle(4)
                            }
                          >
                            <label className="itemLabel noWrap">
                              服务请求哈希
                            </label>
                            <span
                              className="itemValue noWrap"
                              title={
                                type === "right"
                                  ? item.isDecrypt
                                    ? data &&
                                      data.req_tx_info &&
                                      data.req_tx_info.tx_hash
                                    : "****"
                                  : selectedPoint
                                  ? exData &&
                                    exData[item.area_id_decrypt] &&
                                    exData[item.area_id_decrypt].req_tx_info
                                      .tx_hash
                                  : noDataPlaceHolder
                              }
                              style={
                                selectedPoint
                                  ? itemValueStyle(item.cardColor - 1)
                                  : itemValueStyle(4 - 1)
                              }
                            >
                              {type === "right"
                                ? item.isDecrypt
                                  ? data &&
                                    data.req_tx_info &&
                                    getRestString(
                                      data.req_tx_info.tx_hash,
                                      8,
                                      6
                                    )
                                  : "****"
                                : selectedPoint
                                ? exData &&
                                  exData[item.area_id_decrypt] &&
                                  getRestString(
                                    exData[item.area_id_decrypt].req_tx_info
                                      .tx_hash,
                                    8,
                                    6
                                  )
                                : noDataPlaceHolder}
                            </span>
                          </div>
                          <div
                            className="contentItem"
                            style={
                              selectedPoint
                                ? contentItemStyle(item.cardColor)
                                : contentItemStyle(4)
                            }
                          >
                            <label className="itemLabel noWrap">
                              请求区块高度
                            </label>
                            <span
                              className="itemValue noWrap"
                              style={
                                selectedPoint
                                  ? itemValueStyle(item.cardColor - 1)
                                  : itemValueStyle(4 - 1)
                              }
                            >
                              {type === "right"
                                ? item.isDecrypt
                                  ? data &&
                                    data.req_tx_info &&
                                    data.req_tx_info.height
                                  : "****"
                                : selectedPoint
                                ? exData &&
                                  exData[item.area_id_decrypt] &&
                                  exData[item.area_id_decrypt].req_tx_info
                                    .height
                                : noDataPlaceHolder}
                            </span>
                          </div>
                          <div
                            className="contentItem"
                            style={
                              selectedPoint
                                ? contentItemStyle(item.cardColor)
                                : contentItemStyle(4)
                            }
                          >
                            <label className="itemLabel noWrap">
                              服务响应哈希
                            </label>
                            <span
                              className="itemValue noWrap"
                              title={
                                type === "right"
                                  ? item.isDecrypt
                                    ? data &&
                                      data.resp_tx_info &&
                                      data.resp_tx_info.tx_hash
                                    : "****"
                                  : selectedPoint
                                  ? exData &&
                                    exData[item.area_id_decrypt] &&
                                    exData[item.area_id_decrypt].resp_tx_info
                                      .tx_hash
                                  : noDataPlaceHolder
                              }
                              style={
                                selectedPoint
                                  ? itemValueStyle(item.cardColor - 1)
                                  : itemValueStyle(4 - 1)
                              }
                            >
                              {type === "right"
                                ? item.isDecrypt
                                  ? data &&
                                    data.resp_tx_info &&
                                    getRestString(
                                      data.resp_tx_info.tx_hash,
                                      8,
                                      6
                                    )
                                  : "****"
                                : selectedPoint
                                ? exData &&
                                  exData[item.area_id_decrypt] &&
                                  getRestString(
                                    exData[item.area_id_decrypt].resp_tx_info
                                      .tx_hash,
                                    8,
                                    6
                                  )
                                : noDataPlaceHolder}
                            </span>
                          </div>
                          <div
                            className="contentItem"
                            style={
                              selectedPoint
                                ? contentItemStyle(item.cardColor)
                                : contentItemStyle(4)
                            }
                          >
                            <label className="itemLabel noWrap">
                              响应区块高度
                            </label>
                            <span
                              className="itemValue noWrap"
                              style={
                                selectedPoint
                                  ? itemValueStyle(item.cardColor - 1)
                                  : itemValueStyle(4 - 1)
                              }
                            >
                              {type === "right"
                                ? item.isDecrypt
                                  ? data &&
                                    data.resp_tx_info &&
                                    data.resp_tx_info.height
                                  : "****"
                                : selectedPoint
                                ? exData &&
                                  exData[item.area_id_decrypt] &&
                                  exData[item.area_id_decrypt].resp_tx_info
                                    .height
                                : noDataPlaceHolder}
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                );
              })}
          </Swiper>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </>
  );
}
