import React from "react";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import HotPoints from "./pages/HotPoints";
import Track from "./pages/Track";
import { pathType } from './store/constant'
const App = () => (
  <ConfigProvider locale={zhCN}>
    <BrowserRouter className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path={pathType.hotpoints} component={HotPoints} />
        <Route path={pathType.track} component={Track} />
        <Route path={pathType.trackchain} component={Track} />
      </Switch>
    </BrowserRouter>
  </ConfigProvider>
);

export default App;
