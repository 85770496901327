import { useEffect, useState, useRef } from "react";
import { Scene, Marker, Popup, MarkerLayer } from "@antv/l7";
import { GaodeMap } from "@antv/l7-maps";
import ApplyServiceCard from "../../components/ApplyServiceCard";
import ResponseServiceCard from "../../components/ResponseServiceCard";
import Navigation from "../../components/Navigation";
import ShadeLayer from "../../components/ShadeLayer";
import MapControl from "../../components/MapControl";
import "./index.scss";
import cooperatorImgUrl from "../../../public/img/cooperator.png";
import titleImgUrl from "../../../public/img/titleImg.png";
import flagImgUrl1 from "../../../public/img/flag1.png";
import flagImgUrl2 from "../../../public/img/flag2.png";
import flagImgUrl3 from "../../../public/img/flag3.png";
import flagImgUrl4 from "../../../public/img/flag4.png";
import popupImgUrl1 from "../../../public/img/popup1.png";
import popupImgUrl2 from "../../../public/img/popup2.png";
import popupImgUrl3 from "../../../public/img/popup3.png";
import popupImgTitleUrl1 from "../../../public/img/popupTitle1.png";
import popupImgTitleUrl2 from "../../../public/img/popupTitle2.png";
import popupImgTitleUrl3 from "../../../public/img/popupTitle3.png";
import hotPointsData from "../../../public/hotPoints";
import { isRangeIn } from "../../helper/numberHelpers";
import { Decrypt } from "../../helper/secretHelpers";
import { getBaseInfo, getRespond } from "../../service/api";
import {
  service_name,
  userDb,
  relate_type,
  applyServiceCardData,
  applyServiceCardData2,
  responseServiceCardData,
  responseServiceCardData2,
} from "../../store/constant";
import { useDidUpdateEffect } from "../../helper/didUpdateEffect";
import { getParameters } from "../../helper/urlParseHelpers";

const urlParams = getParameters(window.location.href);
const hotPointsNavData = {
  ...userDb.find((item) => {
    return item.user_id === urlParams.user_id;
  }),
  ...{
    title: "区域人口热力查询",
    titleImgUrl,
  },
};

const useInterval = (callback, delay) => {
  const saveCallback = useRef(callback);
  useEffect(() => {
    saveCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    if (delay !== null) {
      const timer = setInterval(() => saveCallback.current(), delay);
      return () => {
        if (timer) {
          clearInterval(timer);
        }
      };
    }
    return () => {};
  }, [delay]);
};

const mapData = {
  scene: null,
  markerLayer: null,
};

let decryptTimeout = null;
const decryptTimeInterval = 5000;
let clickTimeout = null;
const clickTimeInterval = 2000;

let carouselInterval = null;
const carouselTimeInterval = 5000;

export default function HotPoints({ history }) {
  document.title = hotPointsNavData.title;

  const [hotPointsDataFilter, setHotPointsDataFilter] = useState(
    hotPointsData.map((item) => {
      const isDecrypt = false;
      let popupImg, popupTitleImg, popupColor, cardColor;
      const el = document.createElement("img");
      el.className = "flagSize";
      if (isRangeIn(item.area_population, 999, 0)) {
        // console.log('0-999')
        el.src = flagImgUrl3;
        popupImg = popupImgUrl3;
        popupTitleImg = popupImgTitleUrl3;
        popupColor = "#08D438";
        cardColor = 3;
      } else if (isRangeIn(item.area_population, 4999, 1000)) {
        // console.log('1000-4999')
        el.src = flagImgUrl2;
        popupImg = popupImgUrl2;
        popupTitleImg = popupImgTitleUrl2;
        popupColor = "#FCEE21";
        cardColor = 2;
      } else if (parseFloat(item.area_population) > 5000) {
        // console.log('>5000')
        el.src = flagImgUrl1;
        popupImg = popupImgUrl1;
        popupTitleImg = popupImgTitleUrl1;
        popupColor = "#FF7606";
        cardColor = 1;
      } else {
        el.src = flagImgUrl4;
        cardColor = 4;
      }

      return {
        ...item,
        lnglat: [item.center_lon, item.center_lat],
        el,
        popupTitleImg,
        popupImg,
        popupColor,
        cardColor,
        isDecrypt,
      };
    })
  );

  const [selectedPoint, setSelectedPoint] = useState(null);

  // 是否为正在解密状态
  const [isDecrypt, setIsDecrypt] = useState(false);
  // 是否为正在点击地图marker状态
  const [isClickMarker, setIsClickMarker] = useState(false);
  // 是否为暂停计时器状态
  const [pauseInterval, setPauseInterval] = useState(true);

  const clickDecryptButton = (decryptItem) => {

    decryptTimeout && clearTimeout(decryptTimeout);
    setIsDecrypt(true);
    decryptTimeout = window.setTimeout(() => {
      setHotPointsDataFilter(() =>
        hotPointsDataFilter.map((item) =>
          item.id === decryptItem.id ? { ...item, isDecrypt: true } : item
        )
      );
    }, decryptTimeInterval);
  };

  const loadScene = (cb) => {
    if (!mapData.scene) {
      mapData.scene = new Scene({
        id: "hotPointsMap",
        logoVisible: false,
        map: new GaodeMap({
          viewMode: "3D",
          center: [113.477171, 23.03186],
          zoom: 9,
          minZoom: 8,
          pitch: 45,
          mapStyle: "amap://styles/c882f12907666fa9cef70720346ff585",
          token: "00955e409626a15a9f182f3f1f2334ce",
        }),
      });
      mapData.scene.on("loaded", () => {
        cb();
      });
    } else {
      cb();
    }
  };

  const loadLayers = () => {
    if (mapData.markerLayer) {
      return;
    }
    mapData.markerLayer = new MarkerLayer();
    hotPointsDataFilter &&
      hotPointsDataFilter.forEach((item, index) => {
        let html = `
            <div
            id="popup${index}"
            class="popupContainer"
            style="background-image: url(${item.popupImg});"
            >
              <h2 class="popupTitle noWrap" title=${
                item.area_name
              } style="background-image: url(${item.popupTitleImg})">${
          item.area_name
        }</h2>
              <div class="popupTextContainer">
                <span class="popupLabel">人口</span>
                <span class="popupText" style="color: ${item.popupColor}">${
          item.isDecrypt ? item.area_population : "****"
        } 人</span>
              </div>
              <div class="popupTextContainer">
                <span class="popupLabel">半径</span>
                <span class="popupText" style="color: ${item.popupColor}">${
          item.isDecrypt ? item.round_radius : "****"
        } km</span>
              </div>
              <div class="popupTextContainer">
                <span class="popupLabel">坐标</span>
                <span class="popupText" style="color: ${item.popupColor}">${
          item.isDecrypt ? item.center_lat.substr(0, 5) : "****"
        }︒N ${
          item.isDecrypt ? item.center_lon.substr(0, 6) : "****"
        }︒E </span>
              </div>
            </div>
           `;
        let popup = new Popup({
          closeButton: false,
          closeOnClick: true,
          index,
          offsets: [0, 60],
        });
        popup.setHTML(html);

        const marker = new Marker({
          extData: { ...item, index },
        });
        marker.setElement(item.el);
        marker.setLnglat([item.center_lon, item.center_lat]);
        marker.setPopup(popup);
        marker.on("click", (event) => {
          if (event.target.isTrusted) {
            clickTimeout && clearTimeout(clickTimeout);
            setIsClickMarker(true);
            clickTimeout = window.setTimeout(() => {
              setIsClickMarker(false);
            }, clickTimeInterval);
          }
          setSelectedPoint({
            ...selectedPoint,
            ...event.data,
          });

          setSelectedIndex(event.data.index);
        });

        mapData.markerLayer.addMarker(marker);
      });
  };

  const reDrawLayer = () => {
    mapData.markerLayer &&
      mapData.markerLayer.getMarkers &&
      mapData.markerLayer.getMarkers().forEach((marker, index) => {
        marker.popup && marker.popup.close();
        marker.setPopup(
          new Popup({
            closeButton: false,
            closeOnClick: true,
            offsets: [0, 0],
          })
        );
        if (marker.markerOption.extData.index === selectedIndex) {
          mapData.scene && mapData.scene.removeLayer(mapData.markerLayer);
          mapData.markerLayer = null;
          loadScene(() => {
            loadLayers();
            mapData.scene.addMarker(mapData.markerLayer);
            mapData.markerLayer.getMarkers().forEach((markerNew) => {
              if (marker.markerOption.extData.index === selectedIndex) {
                const preIndex =
                  marker.markerOption.extData.index < 1
                    ? hotPointsDataFilter.length - 1
                    : marker.markerOption.extData.index - 1;
                const preMarker = mapData.markerLayer.getMarkers()[preIndex];
                !document.getElementById(`popup${preIndex}`) &&
                  preMarker.markerOption.element.click();
              }
            });
          });
        }
      });
  };

  const [selectedIndex, setSelectedIndex] = useState(null);

  useInterval(() => {
    if (
      !pauseInterval &&
      !isDecrypt &&
      !isClickMarker &&
      mapData.markerLayer &&
      mapData.scene
    ) {
      mapData.markerLayer &&
        mapData.markerLayer.getMarkers().forEach((marker, index) => {
          if (marker.markerOption.extData.index === selectedIndex) {
            !document.getElementById(`popup${selectedIndex}`) &&
              marker.markerOption.element.click();
            const preIndex =
              marker.markerOption.extData.index < 1
                ? hotPointsDataFilter.length - 1
                : marker.markerOption.extData.index - 1;
            const preMarker = mapData.markerLayer.getMarkers()[preIndex];
            preMarker && preMarker.popup && preMarker.popup.close();
            if (selectedIndex < hotPointsDataFilter.length - 1) {
              setSelectedIndex(selectedIndex + 1);
            } else {
              setSelectedIndex(
                selectedIndex - mapData.markerLayer.getMarkers().length + 1
              );
            }
          }
        });
    }
  }, carouselTimeInterval);

  const [areaIdBaseInfo, setAreaIdBaseInfo] = useState(applyServiceCardData);
  const [areaUserCountBaseInfo, setAreaUserCountBaseInfo] = useState(
    applyServiceCardData2
  );

  const getBaseInfoFromService = async function (serviceName, relate_type) {
    const respond = await getBaseInfo(serviceName, { relate_type });
    switch (serviceName) {
      case service_name.area_user_count:
        setAreaUserCountBaseInfo({ ...areaUserCountBaseInfo, ...respond });
        break;
      case service_name.area_id:
        setAreaIdBaseInfo({ ...areaIdBaseInfo, ...respond });
        break;
      default:
        break;
    }
  };

  const [areaIdRespond, setAreaIdRespond] = useState(responseServiceCardData);
  const [areaUserCountRespond, setAreaUserCountRespond] = useState(
    responseServiceCardData2
  );

  const getRespondFromService = async function (serviceName, params) {
    const respond = await getRespond(serviceName, params);

    if (
      respond &&
      respond.output &&
      JSON.parse(respond.output) &&
      JSON.parse(respond.output).body.areas_info
    ) {
      const copyHotPointsDataFilter = [];
      hotPointsDataFilter.forEach((item) => {
        const copyItem = { ...item };

        JSON.parse(respond.output).body.areas_info.forEach((subItem) => {
          if (Decrypt(subItem.area_id) == copyItem.area_id) {
            copyItem.area_id_decrypt = subItem.area_id;
            copyHotPointsDataFilter.push(copyItem);
          }
        });
      });

      setHotPointsDataFilter(() => {
        setSelectedIndex(0);
        return copyHotPointsDataFilter;
      });
    } else {
      setHotPointsDataFilter([]);
    }

    switch (serviceName) {
      case service_name.area_user_count:
        setAreaUserCountRespond({ ...areaUserCountRespond, ...respond });
        break;
      case service_name.area_id:
        setAreaIdRespond({ ...areaIdRespond, ...respond });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getBaseInfoFromService(service_name.area_id, relate_type);
    getBaseInfoFromService(service_name.area_user_count, relate_type);
    getRespondFromService(service_name.area_user_count, {
      request_id: urlParams.request_id || "",
    });
    return () => {
      decryptTimeout && clearTimeout(decryptTimeout);
      clickTimeout && clearTimeout(clickTimeout);
      carouselInterval && clearInterval(carouselInterval);
    };
  }, []);

  useDidUpdateEffect(() => {
    isDecrypt && reDrawLayer();
    isDecrypt && setIsDecrypt(false);
  }, [hotPointsDataFilter]);

  const [isSelectedIndexFirstChange, setIsSelectedIndexFirstChange] = useState(true)
  useDidUpdateEffect(() => {
    isSelectedIndexFirstChange && loadLayers();
    isSelectedIndexFirstChange &&
      loadScene(() => {
        mapData.scene.addMarker(mapData.markerLayer);
        setPauseInterval(false);
        setIsSelectedIndexFirstChange(false)
      });
  }, [selectedIndex]);

  const cardMouseEnter = (e) => {
    setPauseInterval(true);
  };
  const cardMouseLeave = (e) => {
    setPauseInterval(false);
  };
  const goBackToHome = () => {
    window.open("https://stage.dbmp.bianjie.ai/servicesummary");
  };

  return (
    <div data-component="HotPoints" className="container">
      <Navigation data={hotPointsNavData} goBackToHome={goBackToHome} />
      <ApplyServiceCard data={areaIdBaseInfo} type="left" />
      <ApplyServiceCard data={areaUserCountBaseInfo} type="right" />
      {isDecrypt && <ShadeLayer type="decrypt" />}
      <ResponseServiceCard
        data={areaIdRespond}
        exData={
          (areaUserCountRespond &&
            areaUserCountRespond.input &&
            areaUserCountRespond.input.tx_info) ||
          {}
        }
        hotPointsDataFilter={hotPointsDataFilter}
        selectedPoint={selectedPoint}
        type="left"
      />
      <ResponseServiceCard
        cardMouseEnter={cardMouseEnter}
        cardMouseLeave={cardMouseLeave}
        data={areaUserCountRespond}
        hotPointsDataFilter={hotPointsDataFilter}
        selectedPoint={selectedPoint}
        clickDecryptButton={clickDecryptButton}
        type="right"
      />
      <ShadeLayer type="map" />
      <MapControl type="hotpoints" />
      <div id="hotPointsMap" className="map"></div>
      <img
        className="cooperatorLogos"
        src={cooperatorImgUrl}
        alt="cooperator"
      ></img>
      <div className="earthImg"></div>
    </div>
  );
}
