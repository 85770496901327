function getLocalTime(nS) {
  return new Date(parseInt(nS) * 1000)
    .toLocaleString()
    .replace(/:\d{1,2}$/, " ");
}

function ChangeHourMinutesNumber(number) {
  return `${Math.trunc(number)} h ${Math.trunc((number % 1) * 60)} min`;
}

export { getLocalTime, ChangeHourMinutesNumber };
