import "./index.scss";
import dataStoreImgURL from "../../../public/img/dataStore.png";

export default function DataStore() {
  return (
    <>
      <div data-component="DataStore" className="container">
        <div className="titleContainer">数据仓库</div>
        <img
          className="dataStoreImg"
          src={dataStoreImgURL}
          alt="dataStore"
        ></img>
      </div>
    </>
  );
}
