import "./index.scss";
import backButtonImgURL from "../../../public/img/backBtn.png";
import { getRestString } from "../../helper/parseString";
export default function Navigation({ data, goBackToHome }) {
  return (
    <>
      <div data-component="Navigation">
        <div className="container">
          <div className="contentContainer">
            <img
              className="backButton"
              src={backButtonImgURL}
              alt="backButton"
              onClick={
                goBackToHome
                  ? goBackToHome
                  : () => {
                      console.error("未绑定返回方法");
                    }
              }
            ></img>
            {/* <div
              className="logoIcon"
              style={{ backgroundImage: `url(${data.logoImgUrl})` }}
            ></div>
            <p className="logoTitle">{data.logoTitle}</p> */}
          </div>

          <div className="titleContainer">
            {/* <img className="titleIcon" src={data.titleImgUrl} alt="titleIcon" /> */}
            <h2 className="title">{data.title}</h2>
          </div>

          <p className="userTitle">
            <span
              className="noWrap"
              style={{ display: "inline-block", width: "120px" }}
              title={data.userTitle}
            >
              {data.userTitle}{" "}
            </span>
            <span
              className="noWrap"
              style={{ display: "inline-block", width: "160px" }}
              title={data.userAdress}
            >
              {" "}
              {getRestString(data.userAdress, 3, 8)}
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
