import "./index.scss";
import applyServiceCardImageURL from "../../../public/img/left.png";
import applyServiceCardRImageURL from "../../../public/img/right.png";
import callCountImgURL from "../../../public/img/callCount.png";
import encryptMethodImgURL from "../../../public/img/encryptMethod.png";
import blocksServiceImgURL from "../../../public/img/blocksService.png";
import { useEffect } from "react";

const getStyles = (type) => {
  const containerStyle =
    type === "left"
      ? {
          left: "84px",
          top: "96px",
          backgroundImage: `url(${applyServiceCardImageURL})`,
        }
      : {
          right: "77px",
          top: "96px",
          backgroundImage: `url(${applyServiceCardRImageURL})`,
        };
  const titleStyle =
    type === "left"
      ? {
          letterSpacing: "5px",
          paddingRight: "20px",
        }
      : {
          letterSpacing: "5px",
          paddingLeft: "40px",
        };
  const contentContainerStyle =
    type === "left"
      ? {}
      : {
          marginLeft: "20px",
        };
  return {
    containerStyle,
    titleStyle,
    contentContainerStyle,
  };
};

export default function ApplyServiceCard({ data, type }) {
  const { containerStyle, titleStyle, contentContainerStyle } = getStyles(type);
  useEffect(() => {}, [data]);
  return (
    <>
      {/* split css */}
      <div data-component="ApplyServiceCard">
        <div className="container" style={containerStyle}>
          <h2 className="label" style={titleStyle}>
            {data.label}
          </h2>
          <div className="contentContainer" style={contentContainerStyle}>
            <div className="countContainer">
              <img
                src={callCountImgURL}
                alt="callCount"
                style={{ width: "75px", height: "68px" }}
              />
              <p className="value">{data.req_total || "****"} 次</p>
              <p className="label">{data.callCountLabel}</p>
            </div>

            <div className="methodsContainer">
              <img
                src={encryptMethodImgURL}
                alt="encryptMethod"
                style={{ width: "75px", height: "68px" }}
              />
              <p className="value">{data.encryptMethod}</p>
              <p className="label">{data.encryptMethodLabel}</p>
            </div>

            <img
              className="blockServiceImg"
              src={blocksServiceImgURL}
              alt="blocksService"
            />

            <div className="blockServiceContainer">
              <p className="value">{data.height || "****"}</p>
              <p className="label">{data.blockServiceLabel}</p>
            </div>

            <div className="serviceContainer" style={{ left: "40px" }}>
              <p className="value noWrap" title={data.creator_name || "****"}>{data.creator_name || "****"}</p>
              <p className="label">{data.serviceCreaterLabel}</p>
            </div>

            <div className="serviceContainer" style={{ right: "60px" }}>
              <p className="value noWrap" title={data.provider_name || "****"}>{data.provider_name || "****"}</p>
              <p className="label">{data.serviceProviderLabel}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
