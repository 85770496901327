import "./index.scss";
import decryptIngImgUrl from "../../../public/img/decryptIng.gif";

export default function ShadeLayer({ type }) {
  const getContainerClassName = () => {
    let classNameString = "";
    switch (type) {
      case "map":
        classNameString = "containerMap";
        break;
      case "decrypt":
        classNameString = "containerDecrypt";
        break;
      default:
        classNameString = "container";
        break;
    }
    return classNameString;
  };

  return (
    <>
      <div data-component="ShadeLayer">
        <div className={getContainerClassName()}>
          {type === "decrypt" && (
            <img
              className="decryptImg"
              src={decryptIngImgUrl}
              alt="decrypt"
            ></img>
          )}
        </div>
      </div>
    </>
  );
}
