import * as moment from "moment";
const testData = [
  {
    key: "B149CA2F485BF3370D124123722B54BE",
    size: 4,
    trajectory: [
      {
        name: "地点",
        start_time: "2021-07-31T01:30:21.424+08:00",
        end_time: "2021-07-31T01:31:21.424+08:00",
        long: 113.379917,
        lat: 23.139939,
        close_contact: 20,
      },
      {
        name: "地点",
        start_time: "2021-07-31T01:31:21.424+08:00",
        end_time: "2021-07-31T01:32:21.424+08:00",
        long: 113.36806,
        lat: 23.13841,
        close_contact: 100,
      },
      {
        name: "地点",
        start_time: "2021-07-31T01:32:21.424+08:00",
        end_time: "2021-07-31T01:33:21.424+08:00",
        long: 113.348953,
        lat: 23.128872,
        close_contact: 300,
      },
    ],
  },
  {
    key: "3A5E0B8EFE955E63A73E3825B354B2F8",
    size: 4,
    trajectory: [
      {
        name: "地点5",
        start_time: "2021-07-31T01:30:21.542+08:00",
        end_time: "2021-07-31T01:31:21.542+08:00",
        long: 113.24651,
        lat: 23.142855,
        close_contact: 140,
      },
      {
        name: "地点6",
        start_time: "2021-07-31T01:31:21.542+08:00",
        end_time: "2021-07-31T01:32:21.542+08:00",
        long: 113.269794,
        lat: 23.138402,
        close_contact: 310,
      },
      {
        name: "地点7",
        start_time: "2021-07-31T01:32:21.542+08:00",
        end_time: "2021-07-31T01:33:21.542+08:00",
        long: 113.276442,
        lat: 23.133184,
        close_contact: 110,
      },
      {
        name: "地点8",
        start_time: "2021-07-31T01:33:21.542+08:00",
        end_time: "2021-07-31T01:34:21.542+08:00",
        long: 113.281131,
        lat: 23.147058,
        close_contact: 10,
      },
    ],
  },
];

// const data = {
//   checked: false,
//   close_contact: 103,
//   countrycode: 86,
//   duration: 0.46,
//   index: 2,
//   msisdn: 13962109892,
//   poi_lat: 23.142855,
//   poi_lon: 113.24651,
//   poi_name: "荔海大厦",
//   start_time: "2021.7.22  8:21",
// };

const parseTrackDatas = (originDatas) => {
  // console.log('moment')
  // console.log(moment)
  if (!originDatas) {
    return [];
  }
  const trackDatas = [];
  originDatas.forEach((item, index) => {
    item.trajectory.forEach((subItem) => {
      const start_time = moment(subItem.start_time, "YYYY-MM-DD HH:mm:ss");
      const end_time = moment(subItem.end_time, "YYYY-MM-DD HH:mm:ss");
      const seconds = end_time.diff(start_time, "seconds");
      // const mintus = seconds / 60;
      // const hours = mintus / 60;
      const trackData = {
        checked: index === 0 ? true : false,
        close_contact: subItem.close_contact,
        // duration: `${hours}h ${mintus}min`,
        duration: seconds / 3600,
        index: index + 1,
        msisdn: item.key,
        poi_lat:
          parseFloat(subItem.lat) > 90
            ? parseFloat(subItem.long)
            : parseFloat(subItem.lat),
        poi_lon:
          parseFloat(subItem.lat) > 90
            ? parseFloat(subItem.lat)
            : parseFloat(subItem.long),
        poi_name: subItem.name,
        start_time: moment(Date.parse(subItem.start_time)).format(
          "YYYY.MM.DD HH:mm"
        ),
      };

      trackDatas.push(trackData);
    });
  });
  return trackDatas;
};

const parseTrackDatasGeo = (originDatas) => {
  if (!originDatas) {
    return [];
  }
  const features = [];
  originDatas.forEach((item, index) => {
    const feature = {
      type: "Feature",
      properties: {
        index: index + 1,
        msisdn: item.key,
        checked: index === 0 ? true : false,
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [[]],
      },
    };
    item.trajectory.forEach((subItem) => {
      const pushItem =
        parseFloat(subItem.lat) > 90
          ? [parseFloat(subItem.lat), parseFloat(subItem.long)]
          : [parseFloat(subItem.long), parseFloat(subItem.lat)];
      feature.geometry.coordinates[0].push(pushItem);
    });
    features.push(feature);
  });

  return {
    type: "FeatureCollection",
    features,
  };
};

export { testData, parseTrackDatas, parseTrackDatasGeo };
