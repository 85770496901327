import logoImgUrl from "../../../public/img/logo.png";
import logoImgUrL2 from "../../../public/img/logo2.png";
import logoImgUrL3 from "../../../public/img/logoMobile.png";

const pathType = {
  hotpoints: '/hotpoints',
  track: '/track',
  trackchain: '/trackchain'
}

const service_name = {
  area_user_count: "latest_user_count_in_area_request",
  area_id: "area_id_request",
  trail_number: "trajectory_private_query",
};

const relate_type = 'area'

const userDb = [
  {
    user_id: "iaa1cwzn8lup6rz9n89p0328gte3ryf56ey24l7np3",
    logoImgUrl,
    logoTitle: "广东省应急厅",
    userTitle: "服务管理员",
    userAdress: "iaa1cwzn8lup6rz9n89p0328gte3ryf56ey24l7np3",
  },
  {
    user_id: "iaa15ammfjf6gh2f88x7nzpzagg53ny3hf5e6s4nc6",
    logoImgUrl: logoImgUrL2,
    logoTitle: "广东省疾控中心",
    userTitle: "服务管理员",
    userAdress: "iaa15ammfjf6gh2f88x7nzpzagg53ny3hf5e6s4nc6",
  },
  {
    user_id: "iaa1fgg95pg4tmlsn0vkwwjlt5lxrwcmnp2c9z7jyg",
    logoImgUrl: logoImgUrL3,
    logoTitle: "广东移动",
    userTitle: "服务管理员",
    userAdress: "iaa1fgg95pg4tmlsn0vkwwjlt5lxrwcmnp2c9z7jyg",
  },
];

const controlType = {
  d: '2d',
  reset: 'reset',
  zoomOut: 'zoomOut',
  zoomIn: 'zoomIn',
}

const applyServiceCardData = {
  label: "区域ID申请服务",
  callCountLabel: "历史调用总数",
  encryptMethod: "代理重加密",
  encryptMethodLabel: "数据加密办法",
  blockServiceLabel: "创建区块",
  serviceCreaterLabel: "服务创建者",
  serviceProviderLabel: "服务提供者",
};

const applyServiceCardData2 = {
  label: "区域实时人数申请服务",
  callCountLabel: "历史调用总数",
  encryptMethod: "混合加密",
  encryptMethodLabel: "数据加密办法",
  blockServiceLabel: "创建区块",
  serviceCreaterLabel: "服务创建者",
  serviceProviderLabel: "服务提供者",
};

const responseServiceCardData = {
  label: "区域实时人数申请服务",
  callCountLabel: "历史调用总数",
  encryptMethod: "混合加密",
  encryptMethodLabel: "数据加密办法",
  blockServiceLabel: "创建区块",
  serviceCreaterLabel: "服务创建者",
  serviceProviderLabel: "服务提供者",
};

const responseServiceCardData2 = {
  label: "区域实时人数申请服务",
  callCountLabel: "历史调用总数",
  encryptMethod: "混合加密",
  encryptMethodLabel: "数据加密办法",
  blockServiceLabel: "创建区块",
  serviceCreaterLabel: "服务创建者",
  serviceProviderLabel: "服务提供者",
};

export { pathType, applyServiceCardData, applyServiceCardData2, responseServiceCardData, responseServiceCardData2, service_name, userDb, relate_type, controlType };
