import "./index.scss";
import { controlType } from "../../store/constant";
import { useState } from "react";
import d2ImgUrl from "../../../public/img/2dControl.png";
import d3ImgUrl from "../../../public/img/3dControl.png";

export default function MapControl({ type, clickControlButton }) {
  const [viewMode, setViewMode] = useState("d2");
  const viewModeImgUrl = {
    d2: d2ImgUrl,
    d3: d3ImgUrl,
  };
  return (
    <>
      <div data-component="MapControl" className="container">
        {type === "track" && (
          <div className="layerContainer">
            <div className="controlContainer">
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
              >
                <div className="controlButtonContainer">
                  <div
                    className="dButton buttonSize"
                    style={{
                      backgroundImage: `url(${viewModeImgUrl[viewMode]})`,
                    }}
                    onClick={() => {
                      setViewMode(viewMode === "d3" ? "d2" : "d3");
                      clickControlButton && clickControlButton(controlType.d);
                    }}
                  ></div>
                  <div
                    className="resetButton buttonSize"
                    onClick={() =>
                      clickControlButton &&
                      clickControlButton(controlType.reset)
                    }
                  ></div>
                  <div
                    className="zoomOutButton buttonSize"
                    onClick={() =>
                      clickControlButton &&
                      clickControlButton(controlType.zoomOut)
                    }
                  ></div>
                  <div
                    className="zoomInButton buttonSize"
                    onClick={() => clickControlButton(controlType.zoomIn)}
                  ></div>
                </div>
                <div className="controllegend"></div>
              </div>
            </div>
            <div className="topLayer"></div>
            <div className="leftLayer"></div>
            <div className="rightLayer"></div>
            <div className="bottomLayer"></div>
          </div>
        )}
        {type === "hotpoints" && (
          <div className="layerContainer">
            <div className="hotpointsLegend"></div>
            <div className="topLayer"></div>
            <div className="leftLayer"></div>
            <div className="rightLayer"></div>
            <div className="bottomLayer"></div>
          </div>
        )}
      </div>
    </>
  );
}
