import { HttpHelper } from "../helper/httpHelpers";
// import { requestThrottler } from "../helper/throttleHttpHelper";

function get(url, params) {
  return new Promise(async (res, rej) => {
    url = `/${url.replace(/^\//, "")}`;
    try {
      let data = await HttpHelper.get(url, params);
      if (data) {
        res(data.data || data);
      } else {
        console.error(`error from ${url}:`, JSON.stringify(data));
        rej(data);
      }
    } catch (err) {
      console.error(`error from ${url}:`, err.message);
      rej(err);
    }
  });
}

// async function throttlerPost(url, payload) {
//   url = `/${url.replace(/^\//, "")}`;
//   try {
//     let data = await requestThrottler(url, payload);
//     if (data) {
//       return data;
//     } else {
//       console.error(`error from ${url}:`, JSON.stringify(data));
//       return data;
//     }
//   } catch (err) {
//     console.error(`error from ${url}:`, err.message);
//     return err;
//   }
// }

const polling = (type, url, params, delay = 2000) => {
  return new Promise((resolve, reject) => {
    const config = {
      params,
    };
    HttpHelper[type](url, config).then((res) => {
      if (res.code === 200 && res.data.status && (res.data.status === 4 || res.data.status === 5)) {
        resolve(res);
      } else {
        setTimeout(() => {
          resolve(polling(type, url, params, delay));
        }, delay);
      }
    });
  });
};

function getRespond(service_name, params) {
  let url = `/service/${service_name}/respond?`;
  const config = {
    params,
  };
  return get(url, config);
}

function getBaseInfo(service_name, params) {
  let url = `/service/${service_name}/base_info`;
  const config = {
    params,
  };
  return get(url, config);
}

export { getBaseInfo, getRespond, polling };
