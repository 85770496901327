const getParameters = function (url) {
  let nameValues = {};
  if (url) {
    const arrUrlParts = url.split("?");
    const urlParameters = arrUrlParts[1] && arrUrlParts[1].split("&");
    if (urlParameters) {
      for (let i = 0; i < urlParameters.length; i++) {
        const nameValue = urlParameters[i].split("=");
        nameValues = {
          ...nameValues,
          [nameValue[0]]: nameValue[1],
        };
      }
    }

    return nameValues;
  }
};

export { getParameters };
